import axios from 'axios'
import store from '../store'

// Флаг debug setup
// 0 - нет
// 1 - только запрос без параметров
// 2 - запрос и ответ с параметрами
var debug = 1

// Версия API
// Проверить, что совпадает с версией API, указанной в бэкенде
// в config.php
var version = 4

if (origin != 'http://localhost:8080') debug = 0

var attempt = 1 // номер попытки соединения
var maxAttempts = 15 // максимальное количество попыток соединения
var url = '' // ссылка api

if (origin.indexOf('localhost:8080') >= 0) {
	url = 'https://sync.api.controlata.ru/'
	// url = 'localhost:8090'
}
else if (origin.indexOf('dev.app.controlata.ru') >= 0) {
	url = 'https://dev.api.controlata.ru/'
}
else {
	url = 'https://api.controlata.ru/'
}

if (debug > 0) {
	console.log('api url: ' + url)
}

const api = axios.create({
	baseURL: url,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(function (config) {
	// Do something before request is sent
	if (debug > 0) {
		console.log('>> api ' + config.url)
		if (debug > 1) {
			if (config.data) console.log(config.data)
			console.log('------')
		}
	}

	if (config.url.substring(config.url.length - 4) !== '.php') {
		config.url = config.url + '.php'
	}

	// Добавляем информацию о версии

	config.headers.common['version'] = version

	// перед каждым запросом читаем токен из localStorage
	// т.к. в соседней вкладке могли обновить токены в localStorage
	// и сделать хранимые в store токены недействительными

	config.headers.common['authorization'] = localStorage.getItem('token')

	if (config.data instanceof FormData) {
		config.headers.common['Content-Type'] = 'multipart/form-data'
	}

	return config
}, function (error) {
	// Do something with request error
	if (debug > 0) {
		console.log('interceptor request error')
	}
	return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data

	attempt = 1
	store.dispatch('dialog/connectionClose')
	store.dispatch('dialog/maintenanceClose')

	if (debug > 1) {
		console.log('<< api ' + response.config.url)
		// if (response.config.data) console.log(response.config.data)
		console.log(response.data)
		console.log('------')
	}

	if (!response.data) {
		throw new Error('Server did not reply')
	}

	if (response.data instanceof Blob) {
		return response
	}

	if (response.data.token !== undefined) {
		if (debug > 1) console.log('-- update token')
		localStorage.setItem('token', response.data.token)
	}

	// Update user data
	if (response.data.user !== undefined) {
		store.dispatch('set', {
			key: "authorized",
			value: true
		})
		store.dispatch('set', {
			key: "user",
			value: response.data.user
		})
	}

	if (!response.data.success) {
		if (debug > 1) console.log('-- response error')
		if (debug > 1) console.log('-- ' + response.data.error)

		if (response.data.error == 'Unauthorized' || response.data.error == 'Restricted') {
			if (debug > 1) console.log('-- logout')

			store.dispatch('logout')
		}
		else if (response.data.error == 'Maintenance') {
			store.dispatch('dialog/maintenanceOpen')
		}
		else if (response.data.error) {
			store.dispatch('dialog/notifyOpen', {
				title: 'Что-то пошло не так',
				message: response.data.error
			})
			throw new Error(response.data.error)
		}
		else {
			throw new Error('Неизвестная ошибка')
		}
	}

	if (response.data.version !== version) {
		// Версия api и версия frontend-a не совпадают

		store.dispatch('set', {
			key: 'needReload',
			value: true
		})
	}

	return response
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	var errorJson = error.toJSON()
	if (debug > 0) {
		console.log('interceptor response error')
		console.log(errorJson)
	}

	// Retry
	if (errorJson.message == 'Network Error') {

		if (attempt <= maxAttempts) {
			var config = errorJson.config

			if (debug > 1) {
				console.log('-- retry connection to ' + errorJson.config.url)
				console.log('-- attempt: ' + attempt)
				console.log(errorJson.config)
			}
			attempt = attempt + 1

			/*
				время между попытками растет по экспоненте
				0,37 сек между 1 и 2 попытками
				1,00 сек между 2 и 3 попытками
				2,72 сек между 3 и 4 попытками
				7,39 сек между 4 и 5
				20,09 сек между 5 и 6
				54,60 сек между 6 и 7
				2 мин 28 сек между 7 и 8
				6 мин 43 сек между 8 и 9
				18 мин 16 сек между 9 и 10
				49 мин 41 сек между 10 и 11
				2 часа 15 мин между 11 и 12
				6 часов 7 мин между 12 и 13
				16 часов 37 мин между 13 и 14
				45 часов 12 мин между 14 и 15
			*/

			if (attempt > 5) {
				store.dispatch('dialog/connectionOpen')
			}

			let delay = Math.exp(attempt - 3) * 1000

			return new Promise(function (resolve) {
				return setTimeout(function () {
					return resolve(api(config))
				}, delay)
			})
		}
		else {
			if (debug > 1) {
				console.log('-- max retry attempts reached')
				console.log('-- end up calling ' + errorJson.config.url)
			}
		}
	}
	else {
		console.log('Other error')
		console.log(errorJson)
		throw new Error(errorJson.message)
	}

	return Promise.reject(error)
})

export default api